//document.title = "";

console.log('nmx.consumer.js');

window.setTimeout(function(){
  //jQuery("input#number").focus().val("4111111111111111");
  //document.title = jQuery(".card-fields-iframe").attr("name");
  //document.title = document.referrer;
  
  if(document.referrer.indexOf("tivose.com")>-1){ 
    //jQuery("head").append('<link type="text/css" rel="stylesheet" href="//www.nutramax.biz/store/shopify/nmx.tivose.buynow.css">');
    //jQuery("head").append('<script type="text/javascript" src="//www.nutramax.biz/store/shopify/nmx.tivose.buynow.js"></script>');
  }
  
  //AddStarRatingsToProductPages()
  //AddStarRatingsToProductGallery();
  
  //GetPreferredCollection();
  
  //AddHolidayClosingMsg();
  AddAcctHistoryMsg();
  
  AddCartToMobileMenu();
  AddVetLoginMessage();
},100);

//jQuery(".footnotes").appendTo(".footnotes-wrapper");

//MoveSupplementFacts();
/*function MoveSupplementFacts(){
  if(!jQuery(".supplement-facts-image-wrapper img").length){
  	jQuery(".product-collateral .entry-content:first img").appendTo(".supplement-facts-image-wrapper");
  } else{
    console.log("waiting to move supplement facts");
  	window.setTimeout(function(){ MoveSupplementFacts(); },1000);
  }
}*/
if (location.href.indexOf("checkout")>-1){
    jQuery("a.breadcrumb__link:contains('Cart')").clone().removeClass("breadcrumb__link").addClass("checkout-top-right-cart-icon").insertBefore(jQuery(".banner a.logo"));
    jQuery(".checkout-top-right-cart-icon").html(jQuery("svg.order-summary-toggle__icon").parent().html());
}

//window.setTimeout(function(){ GetPreferredCollection(); },1000);
//window.setTimeout(function(){ GetPreferredCollection(); },2000);
function GetPreferredCollection(){
    //"barcode":"123123"}]
    
    if(jQuery("script:contains('barcode')").length){
        var productsObjid = jQuery("script:contains('barcode'):last").html().substr(0,25);
        document.title = productsObjid;
        eval('var collection = '+productsObjid+'["variants"][0].barcode');
        
        jQuery(".category-title h3").text(collection+" Products");
        document.title = collection;
        
        //if(location.href.indexOf(".com/products") > -1){
        //    document.location = location.href.replace(".com/products",".com/collection/"+type.toLowerCase().replace(/\s/g,"-")+"-products/products")
        //}
    }

}



/*AddSlideClassToHomepageBanner();

function AddSlideClassToHomepageBanner(){
    if (jQuery(".nivo-main-image[src*='nivo_slide_1.jpg']").length){
        jQuery(".nivoSlider").attr("class","nivoSlider slide1");
    } else if (jQuery(".nivo-main-image[src*='nivo_slide_2.jpg']").length){
        jQuery(".nivoSlider").attr("class","nivoSlider slide2");
    }
    
    window.setTimeout(function(){ AddSlideClassToHomepageBanner() },2000);
}    
*/


function AddHolidayClosingMsg(){
    //if (jQuery(".page-title").text().toLowerCase().trim() == "contact us" || jQuery(".page-title").text().toLowerCase().trim() == "my cart"){
        
        var holiday_closing_msg = '<div class="holiday-closing-msg wide-notice"><h1>Wishing you a Merry Christmas and Happy Holidays from our family to yours!</h1></div>';
        jQuery("#contact-us .main-container.container").prepend(holiday_closing_msg);
        jQuery("#your-shopping-cart .main-container.container").append(holiday_closing_msg);
        jQuery(".holiday-closing-msg").append('<p>In order for our employees and their families to enjoy the holiday season, we will be <b>closed</b> Friday, December 22 at 5:00 PM EST through Monday, January 1, 2018. We will reopen at 8:30 AM EST on Tuesday, January 2, 2018. Orders placed by 3:00 PM EST on Friday, December 22 will be shipped that day. </p>');
        jQuery(".holiday-closing-msg").append('<p>All orders placed after 3:00 PM EST on Friday, December 22 will be shipped on or after January 2, 2018.</p>');
    //}

}


function AddAcctHistoryMsg(){
        jQuery(".acct-history-msg").append('<p>Questions about your order history? Please call Customer Service at 888-886-6442.</p>');
        jQuery(".my-order p:contains('placed any orders yet')").remove();
        jQuery(".acct-history-msg").appendTo(".my-order");
}


function AddCartToMobileMenu(){
        jQuery("#mobile-menu").append('<li class="level0 level-top "><a href="/cart" class="level-top">View Cart</a></li>');
}

function AddVetLoginMessage(){
        jQuery(".col-box-inner.login-users").append('<div class="vet-login-notice">Vet Professionals <a href="http://vet.nutramaxstore.com" target="_blank">Log In</a></div>');
}



if (location.href.indexOf("checkout")==-1){
  
    jQuery(document).ready(function(){
      // fix enlarge product image on mobile
      //window.setTimeout(function(){
        if (jQuery("#image-main").length){
            jQuery("body").prepend('<style>body:not(.showing-enlarged-image) .mobile-enlarged-image { display:none; } </style><div class="mobile-enlarged-image" onclick="jQuery(\'body\').removeClass(\'showing-enlarged-image\');" style="background:url(\''+jQuery("#image-main").attr("src")+'\') center /contain no-repeat #ffffff;"></div>');
            jQuery("#image-main").click(function(){ 
              window.setTimeout(function(){
                  if(!jQuery(".fancybox-image").length){
                      jQuery("body").addClass("showing-enlarged-image");
                  } else{
                      jQuery("body").removeClass("showing-enlarged-image"); 
                  }
              },800);
            });
        }
      //},100);
      
    });
    
    jQuery(document).ready(function(){
      window.setTimeout(function(){
        if (jQuery("body").attr("id").indexOf("bargain-buy")>-1){
          console.log("bargain buys");
          //jQuery(".page-title").after('<div class="bargain-buys-notice"><ul><li>All Bargain Buys will have an expiration date of 6-15 months remaining, and also still meet our high quality standards.</li><li>There are limited quantities of Bargain Buys.</li><li>No additional discounts, promotion codes, or special offers can be combined with a Bargain Buy purchase.</li><li>All Bargain Buys sales are final and can not be returned.</li></ul></div>');
          jQuery(".product-main.main-container .product-type-main").prepend('<div class="bargain-buys-notice"><span>All Bargain Buys will have an expiration date of 6-15 months remaining, and also still meet our high quality standards.</span><span>There are limited quantities of Bargain Buys.</span><span>No additional discounts, promotion codes, or special offers can be combined with a Bargain Buy purchase.</span><span>All Bargain Buys sales are final and can not be returned.</span></div>');
          jQuery(".main-container.container").prepend('<div class="bargain-buys-notice"><span>All Bargain Buys will have an expiration date of 6-15 months remaining, and also still meet our high quality standards.</span><span>There are limited quantities of Bargain Buys.</span><span>No additional discounts, promotion codes, or special offers can be combined with a Bargain Buy purchase.</span><span>All Bargain Buys sales are final and can not be returned.</span></div>');
          
        } else{
          console.log("not bargain buys"); 
        }  
      },1000);
    });
    
    
    
    jQuery(document).ready(function(){
      window.setTimeout(function(){
        if (jQuery("#successDiv").length && jQuery("#successDiv .scroll-div").text().trim() == ""){
          jQuery(".success-stories-submit-form-btn").clone().addClass("success-stories-bottom-btn").appendTo("#successDiv .scroll-div").click(function(){ OpenSubmitFormPopup(); });
          jQuery("#successDiv .scroll-div").css("min-height","0px");
        }
      },1000);
    });
    
    
    jQuery(document).ready(function() {
        if(jQuery('#-your-shopping-cart').length){
          jQuery('#your-shopping-cart form').ajaxForm({
              delegation: true,
              target: '.main-container .main-wrapper'
          });       
        }
    });

}